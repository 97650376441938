exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-airdrop-tsx": () => import("./../../../src/pages/airdrop.tsx" /* webpackChunkName: "component---src-pages-airdrop-tsx" */),
  "component---src-pages-allocations-tsx": () => import("./../../../src/pages/allocations.tsx" /* webpackChunkName: "component---src-pages-allocations-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manifesto-tsx": () => import("./../../../src/pages/manifesto.tsx" /* webpackChunkName: "component---src-pages-manifesto-tsx" */)
}

